import React, { useContext, useEffect, useState } from "react";
import "../../assets/css/Navbar/Navbar.css";

import logo from "../../assets/images/logo.png";
import Timer from "../../assets/icons/timer.png";
import Arrow from "../../assets/icons/arrow.png";
import Heat from "../../assets/icons/heat.png";

import Location from "../Reusables/Location/Location";
import Button from "../Reusables/Button/Button";
import TimeRangePopup from "../Modal/TimeRangePopup/TimeRangePopup";
import { AppContext } from "../../Context/Context";
import heatMapIcon from "../../assets/icons/fire.png";
import {
  reloadApp,
  externalLinkNavigator,
  getLocalStorageItem,
} from "../../util/helper/helperFunctions";

const Navbar = ({ siteLocations }) => {
  //Nav Quality Heatmap
  const {
    navQualityHeatmapIsEnabled,
    setNavQualityHeatmapIsEnabled,
    newTimeMachineModeIsEnabled,
  } = useContext(AppContext);
  const { timeRangePopup, setTimeRangePopup } = useContext(AppContext);
  const { timeMachineModeIsEnabled, setTimeMachineModeIsEnabled } =
    useContext(AppContext);
  const { timeMachineV2ModeIsEnabled, setTimeMachineV2ModeIsEnabled } =
    useContext(AppContext);
  const { additionalDashboardModalIsOpen, setAdditionalDashboardModalIsOpen } =
    useContext(AppContext);
  const [location, setLocation] = useState("");
  const [isHeatmapModeEnabled, setIsHeatmapModeEnabled] = useState(false);

  useEffect(() => {
    let selectedLocation = localStorage.getItem("locationSelected");
    if (selectedLocation) {
      setLocation(selectedLocation);
    }
  }, [localStorage.getItem("locationSelected")]);

  const { locationModal, setLocationModal } = useContext(AppContext);

  const openTimeMachineRange = () => {
    setTimeRangePopup(!timeRangePopup);
    localStorage.setItem("timeRangeType", JSON.stringify("timeMachine"));
  };

  const openNavQualityHeatmapRange = () => {
    setTimeRangePopup(!timeRangePopup);
    localStorage.setItem("timeRangeType", JSON.stringify("navHeatmap"));
  };

  const openNewTimeMachine = () => {
    if (!newTimeMachineModeIsEnabled) {
      let url = window.location.origin + externalLinkNavigator("timeMachine");
      window.open(url).focus();
      localStorage.setItem("timeRangeType", JSON.stringify("timeMachineV2"));
      return;
    }
    setTimeRangePopup(!timeRangePopup);
  };

  const openHeatMapRange = () => {
    setTimeRangePopup(!timeRangePopup);
    localStorage.setItem("timeRangeType", JSON.stringify("heatmap"));
  };

  const openAdditionalDashboard = () => {
    setAdditionalDashboardModalIsOpen(!additionalDashboardModalIsOpen);
    document.body.classList.add("prevent-scroll");
  };

  const isNavAndTimeMachineEnabled = () => {
    let currentPlant = getLocalStorageItem("locationCode");
    let enabledPlants = ["TAC"];
    if (enabledPlants.includes(currentPlant)) {
      return true;
    } else {
      return false;
    }
  };

  const renderTimeMachineOrHeatmap = () => {
    if (!timeMachineModeIsEnabled && !navQualityHeatmapIsEnabled) {
      return (
        <>
          <Button
            btnClass={timeMachineModeIsEnabled ? "time-machine-selected" : ""}
            icon={Timer}
            onClick={openTimeMachineRange}
            placeholder="Time Machine"
          ></Button>

          {isNavAndTimeMachineEnabled() && !timeMachineModeIsEnabled ? (
            <Button
              btnClass={
                timeMachineV2ModeIsEnabled ? "time-machine-selected" : ""
              }
              icon={Timer}
              onClick={openNewTimeMachine}
              placeholder="New Time Machine"
            ></Button>
          ) : null}

          {isNavAndTimeMachineEnabled() ? (
            <Button
              btnClass={navQualityHeatmapIsEnabled ? "heat-map-selected" : ""}
              imgClass="heatmap-icon"
              icon={heatMapIcon}
              onClick={openNavQualityHeatmapRange}
              placeholder="Nav Quality Heatmap"
            ></Button>
          ) : null}
        </>
      );
    } else {
      if (timeMachineModeIsEnabled) {
        return (
          <Button
            btnClass={timeMachineModeIsEnabled ? "time-machine-selected" : ""}
            icon={Timer}
            onClick={openTimeMachineRange}
            placeholder="Time Machine"
          ></Button>
        );
      } else if (isNavAndTimeMachineEnabled()) {
        if (navQualityHeatmapIsEnabled) {
          return (
            <Button
              btnClass={navQualityHeatmapIsEnabled ? "heat-map-selected" : ""}
              imgClass="heatmap-icon"
              icon={heatMapIcon}
              onClick={openNavQualityHeatmapRange}
              placeholder="Nav Quality Heatmap"
            ></Button>
          );
        }
      }
    }
  };

  return (
    <div className="navbar-container">
      <div className="left-section">
        <div className="logo">
          <a href="/">
            <img src={logo} alt="Niagara LGV One" />
          </a>
        </div>
        <div className="location">
          <Location
            allLocations={siteLocations}
            location={location}
            onClick={() => setLocationModal(true)}
          />
        </div>
      </div>
      <div className="right-section">
        {renderTimeMachineOrHeatmap()}
        {timeMachineModeIsEnabled || navQualityHeatmapIsEnabled ? null : (
          <>
            <Button
              btnClass={isHeatmapModeEnabled ? "heat-map-selected" : ""}
              imgClass="heatmap-icon"
              icon={heatMapIcon}
              onClick={openHeatMapRange}
              placeholder="Heatmap"
            ></Button>
            <Button
              onClick={() => openAdditionalDashboard()}
              btnClass="reverse-content"
              imgClass="reverse-content-img"
              placeholder="Additional Dashboards"
              icon={Arrow}
            />
          </>
        )}
      </div>
      <TimeRangePopup />
    </div>
  );
};

export default Navbar;
