import {
  useEffect,
  React,
  useState,
  useContext,
  useMemo,
  useCallback,
} from "react";
import {
  getLocalStorageItem,
  timeFrameFormatter,
} from "../../util/helper/helperFunctions";

import "../../assets/css/TimeMachine/timeMachineV2.css";
import "../../assets/css/TimeMachine/timeMachine.css";
import { AppContext } from "../../Context/Context";
import TimeMachineMap from "./TimeMachineMap";
import TimeMachineV2Controller from "../Modal/TimeMachineController/TimeMachineV2Controller";
import { getTimeMachineData } from "../../util/network/databricksHandler";
import {
  groupLgvsByTimeFrame,
  groupDockByTimeFrame,
  groupProductinLineByTimeFrame,
  groupRackByTimeFrame,
  groupBlockByTimeFrame,
  generateTimeFrame,
  getTimeInISOFromZero,
} from "./helperFunction";

// Please double check before modifying any date object direcly it will
// direct to whole timemachine feature to loading state
const TimeMachineV2 = ({ socketConnection }) => {
  const {
    setNewTimeMachineModeIsEnabled,
    setTimeRangePopup,
    timeMachineTimeFrames,
  } = useContext(AppContext);

  const [isDataLoading, setIsDataLoading] = useState(false);
  const [startTimeObj, setstartTimeObj] = useState(null);
  const [endTimeObj, setEndTimeObj] = useState();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isNextMinuteDataLoaded, setIsNextMinuteDataLoaded] = useState(false);
  const [currentTimeObj, setCurrentTimeObj] = useState();
  const [lastRequestTimeObj, setLastRequestTimeObj] = useState(null);
  const [liveData, setLiveData] = useState({});
  const [timeFrame, setTimeFrame] = useState([]);
  const [framesData, setFramesData] = useState({}); // generateTimeFrame(startTimeObj, endTimeObj)

  const updateFrequency = 1000; // in ms => 1000 = 1s

  const resetTimeMachine = useCallback(() => {
    setIsPlaying(false);
    setIsNextMinuteDataLoaded(false);
    setCurrentTimeObj(null);
    setLiveData({});
    setFramesData({});
    setstartTimeObj(null);
    setEndTimeObj(null);
  }, []);

  // Function to set live data
  const setLiveDataFunc = useCallback(
    (currentTimeObj) => {
      const timeKey = currentTimeObj.toISOString();
      const currentMinunteObj = new Date(currentTimeObj);
      currentMinunteObj.setSeconds(0);
      let currentMinunteKey = currentMinunteObj.toISOString();
      setLiveData({
        LGV: framesData[timeKey]?.LGV || {},
        // BLOCK: framesData[timeKey]?.BLOCK || {},
        DOCKDOOR:
          framesData[timeKey]?.DOCKDOOR ||
          framesData[currentMinunteKey]?.DOCKDOOR ||
          {},
        PRODUCTION_LINE:
          framesData[timeKey]?.PRODUCTION_LINE ||
          framesData[currentMinunteKey]?.PRODUCTION_LINE ||
          {},
        RACK:
          framesData[timeKey]?.RACK ||
          framesData[currentMinunteKey]?.RACK ||
          [],
        BLOCK:
          framesData[timeKey]?.BLOCK ||
          framesData[currentMinunteKey]?.BLOCK ||
          [],
      });
    },
    [framesData]
  );

  // Load the next minute data if the current second is >= 30
  const loadNextMinuteData = useCallback(
    (currentTimeObj) => {
      if (currentTimeObj.getSeconds() >= 30) {
        if (!isNextMinuteDataLoaded) {
          let tmObj = new Date(currentTimeObj.getTime());
          tmObj.setMinutes(currentTimeObj.getMinutes() + 1);
          tmObj.setSeconds(0);
          if (tmObj < endTimeObj) {
            setIsNextMinuteDataLoaded(true);
            // console.log("load next minute data");
            loadData(tmObj);
          }
        }
      } else if (currentTimeObj.getSeconds() === 0) {
        setIsNextMinuteDataLoaded(false);
      }
    },
    [isNextMinuteDataLoaded, endTimeObj]
  );

  const mergeValuesInFrameData = (newObj) => {
    if (Object.keys(newObj).length) {
      setFramesData((prevData) => {
        Object.keys(newObj).forEach((key) => {
          if (prevData[key]) {
            //
            prevData[key] = { ...prevData[key], ...newObj[key] };
          } else {
            //
            prevData[key] = { ...newObj[key] };
          }
        });
        return {
          ...prevData,
          // Adding new key
        };
      });
    }
  };
  // Load data functions
  const loadLGVData = useCallback(
    async (timeObj) => {
      let plantCode = getLocalStorageItem("locationCode");
      let { startTime, endTime } = getTimeInISOFromZero(timeObj);
      let lgvResp = await getTimeMachineData(
        "LGV",
        plantCode,
        startTime,
        endTime
      );
      if (lgvResp) {
        let lgvDataFrame = groupLgvsByTimeFrame(lgvResp);

        let newFrameData = {};
        for (let timestamp in lgvDataFrame) {
          newFrameData[timestamp] = {
            LGV: lgvDataFrame[timestamp],
          };
        }

        mergeValuesInFrameData(newFrameData);
      }
    },
    [framesData]
  );

  const loadProductionLinesData = useCallback(
    async (timeObj) => {
      let plantCode = getLocalStorageItem("locationCode");
      let { startTime, endTime } = getTimeInISOFromZero(timeObj);

      let productionLineResp = await getTimeMachineData(
        "PROD_LINES",
        plantCode,
        startTime,
        endTime
      );
      if (productionLineResp) {
        let productionLineDataFrame =
          groupProductinLineByTimeFrame(productionLineResp);

        let newFrameData = {};
        for (let timestamp in productionLineDataFrame) {
          newFrameData[timestamp] = {
            PRODUCTION_LINE: productionLineDataFrame[timestamp],
          };
        }

        mergeValuesInFrameData(newFrameData);
        // setFramesData(prevData => ({
        //   ...prevData,
        //   ...newFrameData, // Adding new key
        // }));

        // setFramesData(framesData);
        // setIsPlaying(true);
      }
    },
    [framesData]
  );

  const loadRackData = useCallback(
    async (timeObj) => {
      let plantCode = getLocalStorageItem("locationCode");
      let { startTime, endTime } = getTimeInISOFromZero(timeObj);
      // auth = "dapia0e33c89f0f76440821ccea79363ecc4";

      let rackResp = await getTimeMachineData(
        "RACK_STORAGES",
        plantCode,
        startTime,
        endTime
      );
      if (rackResp) {
        let rackDataFrame = groupRackByTimeFrame(rackResp);

        let newFrameData = {};
        for (let timestamp in rackDataFrame) {
          newFrameData[timestamp] = {
            RACK: rackDataFrame[timestamp],
          };
        }

        mergeValuesInFrameData(newFrameData);
      }
    },
    [framesData]
  );

  const loadBlockData = useCallback(
    async (timeObj) => {
      let plantCode = getLocalStorageItem("locationCode");
      let { startTime, endTime } = getTimeInISOFromZero(timeObj);

      let blockResp = await getTimeMachineData(
        "BLOCK_STORAGES",
        plantCode,
        startTime,
        endTime
      );
      if (blockResp) {
        let blockDataFrame = groupBlockByTimeFrame(blockResp);

        let newFrameData = {};
        for (let timestamp in blockDataFrame) {
          newFrameData[timestamp] = {
            BLOCK: blockDataFrame[timestamp],
          };
        }

        mergeValuesInFrameData(newFrameData);
      }
    },
    [framesData]
  );

  const loadDockDoorData = useCallback(
    async (timeObj) => {
      let plantCode = getLocalStorageItem("locationCode");
      let { startTime, endTime } = getTimeInISOFromZero(timeObj);
      let dockResp = await getTimeMachineData(
        "DOCKS",
        plantCode,
        startTime,
        endTime
      );
      if (dockResp) {
        let dockDoorDataFrame = groupDockByTimeFrame(dockResp);

        let newFrameData = {};
        for (let timestamp in dockDoorDataFrame) {
          newFrameData[timestamp] = {
            DOCKDOOR: dockDoorDataFrame[timestamp],
          };
        }

        mergeValuesInFrameData(newFrameData);
      }
    },
    [framesData]
  );

  // Load data for a given time
  const loadData = useCallback(
    (startTime) => {
      // console.log("load data at", startTime.toISOString());
      loadLGVData(startTime);
      loadBlockData(startTime);
      loadDockDoorData(startTime);
      loadProductionLinesData(startTime);
      loadRackData(startTime);
    },
    [loadLGVData, loadDockDoorData]
  );

  const calcCurrentSeekSecond = useCallback(() => {
    if (currentTimeObj) {
      try {
        let value = timeFrame.indexOf(currentTimeObj?.toISOString());
        if (value && value > -1) return value + 1;
      } catch (error) {}
    }
    return 0;
  }, [currentTimeObj, timeFrame]);

  const backward15Seconds = useCallback(() => {
    let tempObj = currentTimeObj.setSeconds(currentTimeObj.getSeconds() - 15);
    tempObj = new Date(tempObj);
    if (tempObj <= startTimeObj) {
      setCurrentTimeObj(new Date(startTimeObj));
      setIsPlaying(false);
    } else {
      setCurrentTimeObj(tempObj);
    }
  }, [currentTimeObj, startTimeObj]);

  const isLiveDataAvailabe = (currentTimeObj) => {
    const currentMinunteObj = new Date(currentTimeObj);
    currentMinunteObj.setSeconds(0);
    let currentMinunteKey = currentMinunteObj.toISOString();
    if (
      Object.keys(framesData?.[currentTimeObj.toISOString()]?.["LGV"] || {})
        ?.length &&
      Object.keys(framesData?.[currentMinunteKey]?.["DOCKDOOR"] || {}).length &&
      Object.keys(framesData?.[currentMinunteKey]?.["PRODUCTION_LINE"] || {})
        ?.length &&
      framesData[currentMinunteKey]?.["RACK"]?.length &&
      framesData[currentMinunteKey]?.["BLOCK"]?.length
    ) {
      if (isDataLoading) setIsDataLoading(false);
      setIsPlaying(true);
      return true;
    } else {
      // console.log("data not available",currentTimeObj.toISOString())
      setIsDataLoading(true);
      setIsPlaying(false);
      if (
        lastRequestTimeObj &&
        lastRequestTimeObj.getTime() !== currentTimeObj.getTime()
      ) {
        loadData(currentTimeObj);
      }
      setLastRequestTimeObj(new Date(currentTimeObj));
    }
  };

  const handleOnChange = (second) => {
    // console.log("selected time", )

    let timestamp = timeFrame[second];
    // console.log("on change", second, timestamp);
    setCurrentTimeObj(new Date(timestamp));
    // currentTimeObj.setSeconds(currentTimeObj.getSeconds() + 15);
  };

  const forwarded15Seconds = useCallback(() => {
    let tempObj = currentTimeObj.setSeconds(currentTimeObj.getSeconds() + 15);
    tempObj = new Date(tempObj);
    if (tempObj >= endTimeObj) {
      let onesecondLessByEndTime = new Date(endTimeObj);
      onesecondLessByEndTime.setSeconds(
        onesecondLessByEndTime.getSeconds() - 1
      );
      setCurrentTimeObj(onesecondLessByEndTime);
      setIsPlaying(false);
    } else {
      setCurrentTimeObj(tempObj);
    }
  }, [currentTimeObj, endTimeObj]);

  // Effect to handle the interval logic
  useEffect(() => {
    if (!isPlaying || currentTimeObj > endTimeObj) return;

    let onesecondLessByEndTime = new Date(endTimeObj);
    onesecondLessByEndTime.setSeconds(onesecondLessByEndTime.getSeconds() - 1);
    const interval = setInterval(() => {
      if (isPlaying) {
        if (currentTimeObj < onesecondLessByEndTime) {
          // console.log(framesData);
          if (isLiveDataAvailabe(currentTimeObj)) {
            setLiveDataFunc(currentTimeObj);
            loadNextMinuteData(currentTimeObj);
            setCurrentTimeObj(new Date(currentTimeObj.getTime() + 1000)); // Increment by 1 second
          }
        } else {
          setIsPlaying(false);
        }
      }
    }, updateFrequency);

    return () => clearInterval(interval);
  }, [
    isPlaying,
    currentTimeObj,
    framesData,
    loadNextMinuteData,
    setLiveDataFunc,
    endTimeObj,
  ]);

  useEffect(() => {
    if (currentTimeObj) {
      isLiveDataAvailabe(currentTimeObj);
    }
  }, [framesData, currentTimeObj]);

  useEffect(() => {
    //disconnect socket to avoid re-rendering through context data change
    if (socketConnection && "disconnect" in socketConnection)
      socketConnection.disconnect();

    setNewTimeMachineModeIsEnabled(true);
    setTimeRangePopup(true);
  }, []);

  useEffect(() => {
    resetTimeMachine();
    if (Object.keys(timeMachineTimeFrames).length) {
      let { fromTimePlant, toTimePlant } = timeFrameFormatter(
        timeMachineTimeFrames
      );
      // fromTimePlant = "2024-09-01T00:00:00.000Z";
      // toTimePlant = "2024-09-01T00:12:00.000Z";

      fromTimePlant = "2024-09-02T18:41:00.000Z";
      toTimePlant = "2024-09-02T18:45:00.000Z";

      setstartTimeObj(new Date(fromTimePlant));
      setCurrentTimeObj(new Date(fromTimePlant));
      setEndTimeObj(new Date(toTimePlant));
    }
  }, [timeMachineTimeFrames, resetTimeMachine]);

  useEffect(() => {
    if (startTimeObj && endTimeObj) {
      setIsDataLoading(true);
      loadData(startTimeObj);
      console.log("timeframes", generateTimeFrame(startTimeObj, endTimeObj));
      setTimeFrame(generateTimeFrame(startTimeObj, endTimeObj));
    }
  }, [startTimeObj, endTimeObj]);

  return (
    <div className="new-ui map-container-wrapper">
      <TimeMachineMap
        liveData={liveData}
        timeMachineTimeFrames={timeMachineTimeFrames}
        isDataLoading={isDataLoading}
      />

      {timeMachineTimeFrames.fromDate && (
        <TimeMachineV2Controller
          totalSeekPosition={timeFrame.length}
          currentSeekPosition={calcCurrentSeekSecond()}
          backward15Seconds={backward15Seconds}
          forwarded15Seconds={forwarded15Seconds}
          isPlaying={isPlaying}
          setIsPlaying={setIsPlaying}
          handleOnChange={handleOnChange}
          timeFrame={timeFrame}
        />
      )}
    </div>
  );
};

export default TimeMachineV2;
