import React, { useEffect, useMemo, useState } from "react";
import { Marker } from "react-leaflet";
import L from "leaflet";
import LgvIcon from "../LgvIcon/LgvIcon"; // Import your LgvIcon component or whatever icon component you use
import {
  getLocalStorageItem,
  mapCoordinate,
  convertReactComponentToHtmlIcon,
  lgvNameRenderer,
} from "../../util/helper/helperFunctions";

const RenderLgv = ({
  mapObject,
  lgvData,
  plantConfig,
  convertedSocketData,
  followingLgvList,
  setSelectedMarkerData,
  setSelectedMarkerType,
  setIsMarkerPopupOpen,
}) => {
  const [convertedData, setConvertedData] = useState([]);

  useEffect(() => {
    // console.log("Render LGV", lgvData);
    if (lgvData) {
      timeMachineLgvCoordinateConverter(lgvData);
    }
  }, [lgvData]);

  const timeMachineLgvCoordinateConverter = (lgvData) => {
    //Get LGV bounds from Plant Config
    if (plantConfig) {
      const { lgvs } = plantConfig.indoorMap;
      const { bounds } = lgvs;

      if (mapObject && lgvData.length) {
        // let filteredData = filterDuplicatesAndNoCoordinates(lgvData);
        lgvData.map((marker, index) => {
          let convertedCoordinates = mapCoordinate(
            marker["longitude"],
            marker["latitude"],
            "lgv",
            bounds
          );
          let convertedPoints = L.point(
            convertedCoordinates.x,
            convertedCoordinates.y
          );
          let convertedLatLng =
            mapObject.map.target.layerPointToLatLng(convertedPoints);
          marker.currentPos = {
            lat: convertedLatLng.lat,
            lng: convertedLatLng.lng,
          };
        });
        setConvertedData(lgvData);
      }
    }
  };

  const markers = useMemo(() => {
    if (
      plantConfig &&
      convertedData &&
      Array.isArray(convertedData) &&
      getLocalStorageItem("enableLgvMarkers")
    ) {
      // Get Plant's LGV Config
      const { lgvs } = plantConfig.indoorMap;
      // LGV's Marker and Group offset values
      const { marker, groupOffset } = lgvs;

      return convertedData.map((lgvMarker, index) => {
        // Check if this LGV is being followed
        const isLgvBeingFollowed = followingLgvList?.find(
          (lgv) => lgv.vehicleNumber === lgvMarker.vehicleNumber
        );

        let markerData = lgvMarker;
        lgvNameRenderer(markerData.vehicleNumber?.toString());

        // Convert React component to HTML icon
        const reactIcon = convertReactComponentToHtmlIcon(
          <LgvIcon
            data={lgvNameRenderer(markerData.vehicleNumber?.toString())}
            status={markerData.status}
            isFollowing={isLgvBeingFollowed !== undefined}
          />,
          "lgv-marker",
          marker.size,
          [40.5, 48]
        );

        const lgvCorrection = groupOffset;

        return (
          <Marker
            type="lgv"
            data={markerData}
            // eventHandlers={{
            //   click: () => {
            //     // setSelectedMarkerData(markerData);
            //     // setSelectedMarkerType("lgv");
            //     // setIsMarkerPopupOpen(true);
            //   },
            // }}
            key={index}
            riseOnHover
            position={[
              markerData.currentPos.lat + lgvCorrection.x,
              markerData.currentPos.lng + lgvCorrection.y,
            ]}
            icon={reactIcon}
          />
        );
      });
    }
    return null; // Return null if conditions are not met
  }, [
    convertedData,
    followingLgvList,
    plantConfig,
    setSelectedMarkerData,
    setSelectedMarkerType,
  ]);

  return <>{markers}</>;
};

export default RenderLgv;
