import { useContext, useEffect, useState } from "react";
import CheckList from "../../../Reusables/CheckList/CheckList";
import "./lgvCheckList.css";
import Button from "../../../Reusables/Button/Button";
import Arrow from "../../../../assets/icons/arrow.png";
import { formatLgvListToCheckList } from "../../../../util/helper/navHeatmap/navHeatmapHelper";
import { AppContext } from "../../../../Context/Context";

const LgvCheckList = ({ data, onChange }) => {
  const { enableCheckList, setEnableCheckList } = useContext(AppContext);
  const [lgvCheckListData, setLgvCheckListData] = useState(null);

  useEffect(() => {
    let lgvCheckList = formatLgvListToCheckList(data);
    setLgvCheckListData(lgvCheckList);
  }, [data]);

  const handler = () => {
    setEnableCheckList(!enableCheckList);
  };
  return (
    <div className="lgv-check-list-container">
      <Button
        placeholder="LGVs"
        btnClass="reverse-content box-shadow"
        imgClass="reverse-content-img"
        icon={Arrow}
        onClick={handler}
      />
      {enableCheckList && lgvCheckListData && (
        <CheckList options={lgvCheckListData} onChange={onChange} />
      )}
    </div>
  );
};

export default LgvCheckList;
