import "../../assets/css/Map/map.css";
import "../../assets/css/YardView/YardView.css";
import L from "leaflet";
import { ImageOverlay, MapContainer } from "react-leaflet";
import { useEffect, React, useState, useContext, useMemo, useRef } from "react";
import {
  getLocalStorageItem,
  mapCoordinate,
  onDragging,
  onDraggingEnd,
  expireAuthToken,
  getPlantMapImageUrl,
} from "../../util/helper/helperFunctions";

import { AppContext } from "../../Context/Context";

import zoomInIcon from "../../assets/icons/add.png";
import zoomOutIcon from "../../assets/icons/minus.png";
import zoomRefreshIcon from "../../assets/icons/refresh.png";
import YardSummary from "../Modal/YardSummary/YardSummary";
import YardLegendsPopup from "../Modal/YardLegendPopup/YardLegendPopup";
import YardDetails from "../Modal/YardDetails/YardDetails";
import YardDockDoors from "./YardDockDoors";
import { yardLegend } from "../../util/network/apiUrl";
import { getApi } from "../../util/network/getApi";

const zoomTransFormArr = [
  "scale(1)",
  "scale(1.2) translate(120px, 50px)",
  "scale(1.4) translate(210px, 90px)",
  "scale(1.6) translate(275px, 120px)",
  "scale(1.8) translate(325px, 135px)",
  "scale(2) translate(360px, 155px)",
  "scale(2.2) translate(380px, 175px)",
  "scale(2.4) translate(400px, 195px)",
  "scale(2.6) translate(420px, 215px)",
  "scale(2.8) translate(440px, 235px)",
  "scale(3) translate(460px, 255px)",
  "scale(3.2) translate(480px, 275px)",
  "scale(3.4) translate(500px, 295px)",
  "scale(3.6) translate(520px, 315px)",
  "scale(3.8) translate(560px, 335px)",
  "scale(4) translate(560px, 355px)",
  "scale(4.2) translate(560px, 375px)",
  "scale(4.4) translate(580px, 395px)",
  "scale(4.6) translate(600px, 415px)",
  "scale(4.8) translate(620px, 435px)",
  "scale(5) translate(640px, 455px)",
];

const YardView = ({ socket }) => {
  const { plantConfig } = useContext(AppContext);
  const [mapObject, setMapObject] = useState();
  const [mapLoader, setMapLoader] = useState(true);
  const mapRef = useRef(null);
  const [zoomLevel, setZoomLevel] = useState(0);
  const [summaryData, setSummaryData] = useState(null);
  const [yardData, setYardData] = useState(null);
  const [legendData, setLegendData] = useState(null);
  const [legendColors, setLegendColors] = useState(null);
  const [yardDetailModalData, setYardDetailModalData] = useState(null);
  const [isYardDetailModalOpen, setIsYardDetailModalOpen] = useState(false);

  //Plant Image Config
  const { appConfig, setAppConfig } = useContext(AppContext);

  useEffect(() => {
    if (!mapObject) return;

    const mapContainer = document.getElementById("yard-view-map-id-reference");
    let transFormString = zoomTransFormArr[zoomLevel];
    if (transFormString) {
      mapContainer.style.transform = transFormString;
      // centerMapView();
    }
  }, [zoomLevel]);

  useEffect(() => {
    async function getLegendData() {
      let legendData = await getApi(
        yardLegend
        // apiHeadersFormatter(auth.accessToken)
      );
      if (legendData.status == 200 && legendData.data) {
        let data = legendData.data;
        setLegendData(data);
        let mergedColors = {};
        data.forEach((item) => {
          const colors = item.colors;
          colors.forEach((item) => {
            mergedColors[item.title] = item.color;
          });
        });
        setLegendColors(mergedColors);
      } else {
        if (
          legendData.response.status == 401 ||
          legendData?.error == "Authentication Error!"
        ) {
          expireAuthToken();
        }
        // getLocations(auth);
      }
    }
    getLegendData();
    return () => {};
  }, []);

  const { yardSummaryModalIsOpen, isYardLegendsPopupOpen } =
    useContext(AppContext);

  useEffect(() => {
    mapObject && mapObject.map.target.doubleClickZoom.disable();
  }, [mapObject]);

  // Utility to combine the yard objects based on location name and then concatenate the delivery number for them.
  const combineDataBasedOnLocationName = (data) => {
    const combined = {};

    data.forEach((item) => {
      if (!combined[item.locationName]) {
        combined[item.locationName] = {
          ...item,
          deliveryNumber: item.deliveryNumber || "",
        };
      } else {
        if (item.deliveryNumber) {
          combined[item.locationName].deliveryNumber += combined[
            item.locationName
          ].deliveryNumber
            ? `, ${item.deliveryNumber}`
            : item.deliveryNumber;
        }
      }
    });

    return Object.values(combined);
  };

  const initiateSocketData = () => {
    socket.on(
      `${getLocalStorageItem("locationCode")}_YARD_CAPACITY_DETAILS`,
      (response) => {
        if (response) {
          if (
            response.results.length !== 0 ||
            response.results !== "Error connecting to datasource!"
          ) {
            setSummaryData(response.results[0]);
          }
        }
      }
    );
    //CAR_YARD_OBJECT_DETAILS
    socket.on(
      `${getLocalStorageItem("locationCode")}_YARD_OBJECT_DETAILS`,
      (response) => {
        if (response) {
          if (
            response.results.length !== 0 ||
            response.results !== "Error connecting to datasource!"
          ) {
            setYardData(combineDataBasedOnLocationName(response.results));
            setMapLoader(false);
          }
        }
      }
    );
  };

  if (socket) {
    initiateSocketData();
  }

  const mapWhenReadyHandler = (map) => {
    setMapObject({ map });
  };

  const RenderMap = useMemo(() => {
    const locationCode = getLocalStorageItem("locationCode");
    if (appConfig && mapObject && plantConfig) {
      let img = getPlantMapImageUrl(appConfig, locationCode, "YARD_MAP");
      let yardMapObj = plantConfig.yard.mapBg;
      const { startX, startY, endX, endY } = yardMapObj.bounds;
      let convertedCoordinates = mapCoordinate(startX, startY, "yardmap");
      let convertedPoints = L.point(
        convertedCoordinates.x,
        convertedCoordinates.y
      );
      let convertedLatLng = mapObject.map.target.unproject(convertedPoints);

      let convertedCoordinates1 = mapCoordinate(endX, endY, "yardmap");
      let convertedPoints1 = L.point(
        convertedCoordinates1.x,
        convertedCoordinates1.y
      );
      let convertedLatLng1 = mapObject.map.target.unproject(convertedPoints1);

      let overlayBounds = [
        [convertedLatLng.lat, convertedLatLng.lng],
        [convertedLatLng1.lat, convertedLatLng1.lng],
      ];
      return (
        <ImageOverlay
          url={img}
          bounds={overlayBounds}
          backgroundColor={yardMapObj.bgColor}
        />
      );
    }
  }, [mapObject, plantConfig, appConfig]);

  useEffect(() => {
    document.getElementById("yard-view-map-id-reference").style.transform =
      "scale(1)";
  }, []);

  const resetZoom = () => {
    document.getElementById("yard-view-map-id-reference").style.transform =
      "scale(1)";
    setZoomLevel(0);
    const map = mapRef.current;
    if (map) {
      map.setView([0, 0]);
    }
  };

  return (
    <div
      className={`map-container-wrapper plant-${getLocalStorageItem(
        "locationCode"
      )}`}
    >
      <div
        onMouseDown={(event) => onDragging(event)}
        onMouseUp={(event) => onDraggingEnd(event)}
        id="yard-view-map-container-id"
        className="map-container"
      >
        {mapLoader ? (
          <div className="loader">
            <h4>Loading Yard View...</h4>
            <div className="spinner"></div>
          </div>
        ) : null}
        <MapContainer
          id="yard-view-map-id-reference"
          style={{
            height: "100%",
            width: "100%",
            borderRadius: "10px",
            background: "green",
          }}
          center={[0, 0]}
          zoom={0}
          maxZoom={6}
          ref={mapRef}
          maxBoundsViscosity={1.0}
          crs={L.CRS.Simple}
          attributionControl={false}
          scrollWheelZoom={false}
          whenReady={(map) => mapWhenReadyHandler(map)}
          // dragging={isMapDraggable}
          zoomControl={false}
          disableDoubleClickZoom={false}
        >
          {legendColors && (
            <YardDockDoors
              yardData={yardData}
              legendColors={legendColors}
              mapObject={mapObject}
              setIsYardDetailModalOpen={setIsYardDetailModalOpen}
              setYardDetailModalData={setYardDetailModalData}
            />
          )}
          {RenderMap}
        </MapContainer>
      </div>

      <div className="zoom-control-center">
        <button
          id="map-zoom-in-btn"
          className="zoom-controls zoom-in-control"
          disabled={zoomLevel >= 20}
          onClick={() => setZoomLevel((curr) => ++curr)}
        >
          <img alt="zoom-in-icon" src={zoomInIcon} />
        </button>
        <button
          id="map-zoom-out-btn"
          className="zoom-controls zoom-out-control"
          disabled={zoomLevel < 1}
          onClick={() => setZoomLevel((curr) => --curr)}
        >
          <img
            alt="zoom-out-icon"
            style={{ opacity: zoomLevel < 1 ? "0.5" : "1" }}
            src={zoomOutIcon}
          />
        </button>
        <button
          className="zoom-controls zoom-reset-controls"
          disabled={zoomLevel == 0}
          onClick={() => resetZoom()}
        >
          <img
            alt="zoom-refresh-icon"
            style={{ opacity: zoomLevel == 0 ? "0.5" : "1" }}
            src={zoomRefreshIcon}
          />
        </button>
      </div>

      {isYardDetailModalOpen && (
        <YardDetails
          yardData={yardDetailModalData}
          setIsYardDetailModalOpen={setIsYardDetailModalOpen}
        />
      )}
      {yardSummaryModalIsOpen && <YardSummary summaryData={summaryData} />}
      {isYardLegendsPopupOpen && legendData && (
        <YardLegendsPopup legendData={legendData} />
      )}
    </div>
  );
};

export default YardView;
