import React, { useMemo, useContext } from "react";
import { Marker, Popup } from "react-leaflet";
import L from "leaflet";
import {
  convertReactComponentToHtmlIcon,
  getLocationGroupOffset,
  mapCoordinate,
  isBlockRackNonLgvEnabled,
  renderPreFilledDoubleBlockPallet,
  renderPreFilledSingleBlockPallet,
  findIsLocationVertical,
  renderRackPallet,
  getBlockRackDataWithLocationOrientation,
  getLocationOrientationOfStorage,
  setRackStorageSize,
  countRacksUsingLPNs,
  // markerSectionGenerator,
  formatName,
} from "../../util/helper/helperFunctions";
import { AppContext } from "../../Context/Context";

const RenderRackStorage = ({ rackData, plantConfig, mapObject }) => {
  //   const { setRackStorageSize } = useContext(AppContext);

  const formatBlockDataStructure = (blockData, type) => {
    /**
     * Group all the locations with key as location name
     */
    let locationGroup = {};
    blockData.forEach((location) => {
      let name;
      if (type === "rack") {
        name = formatName(location.location);
      } else {
        name = location.location;
      }
      if (!locationGroup[name]) {
        locationGroup[name] = [];
      }
      locationGroup[name].push(location);
    });

    if (type === "block") {
      /**
       * Group all the pallets inside location with key as palletPosition as key
       */
      for (let location in locationGroup) {
        let palletGroup = {};
        let currentLocationIterated = locationGroup[location];
        currentLocationIterated.forEach((palletSet) => {
          let pallet = palletSet.palletPosition;
          if (!palletGroup[pallet]) {
            palletGroup[pallet] = [];
          }
          palletGroup[pallet].push(palletSet);
        });
        locationGroup[location] = palletGroup;

        /**
         * Group all the Levels inside Pallets with key as Pallet Level
         */
        for (let pallet in palletGroup) {
          let levelGroup = {};
          palletGroup[pallet].forEach((levelSet) => {
            let level = levelSet.row;
            if (!levelGroup[level]) {
              levelGroup[level] = [];
            }
            levelGroup[level].push(levelSet);
          });
          palletGroup[pallet] = levelGroup;
        }
      }
    }
    return locationGroup;
  };

  const markerSectionGenerator = (
    locationsList,
    transitionPallet,
    doubleRows,
    pbcRows,
    totalPositions,
    locationMetaData
  ) => {
    let sectionList = [];

    //Rack List
    let racksList = [];

    //Block List
    //Single Column List
    let blockSingleColumnList = [];

    //Double Rows List
    let doubleList1 = [];
    let doubleList2 = [];
    let doubleList3 = [];
    let doubleList4 = [];

    //PBC Rows List
    let pbcList1 = [];
    let pbcList2 = [];
    let pbcList3 = [];
    let pbcList4 = [];

    if (transitionPallet === undefined) {
      //Racks
      Object.values(locationsList).forEach((location) => {
        racksList.push(location);
      });

      sectionList.push(racksList);
    } else {
      //Blocks
      //Double Rows
      let totalDoubleRows = doubleRows;
      //Pallets for Double Rows
      let palletsInDoubleRow =
        (totalPositions / 2 - transitionPallet) / totalDoubleRows;

      //PBC Rows
      let totalPbcRows = pbcRows;
      //PBC Rows Start Pallet Index
      let pbcRowsStartPalletIndex = totalPositions / 2 - transitionPallet;
      //Pallets for Pbc Rows
      let palletsInPbcRow =
        (totalPositions / 2 - transitionPallet) / totalPbcRows;

      Object.values(locationsList).forEach((location, index) => {
        if (transitionPallet === null) {
          blockSingleColumnList.push(location);
        } else {
          if (index <= palletsInDoubleRow * totalDoubleRows) {
            // console.log("index", index);
            if (totalDoubleRows === 1) {
              if (index <= palletsInDoubleRow - 1) {
                doubleList1.push(location);
              }
            } else if (totalDoubleRows === 2) {
              if (index <= palletsInDoubleRow - 1) {
                doubleList1.push(location);
              } else if (
                index >= palletsInDoubleRow &&
                index <= palletsInDoubleRow * 2 - 1
              ) {
                doubleList2.push(location);
              }
            } else if (totalDoubleRows === 3) {
              if (index <= palletsInDoubleRow - 1) {
                doubleList1.push(location);
              } else if (
                index >= palletsInDoubleRow &&
                index <= palletsInDoubleRow * 2 - 1
              ) {
                doubleList2.push(location);
              } else if (
                index >= palletsInDoubleRow * 2 &&
                index <= palletsInDoubleRow * 3 - 1
              ) {
                doubleList3.push(location);
              }
            } else if (totalDoubleRows === 4) {
              if (index <= palletsInDoubleRow - 1) {
                doubleList1.push(location);
              } else if (
                index >= palletsInDoubleRow &&
                index <= palletsInDoubleRow * 2 - 1
              ) {
                doubleList2.push(location);
              } else if (
                index >= palletsInDoubleRow * 2 &&
                index <= palletsInDoubleRow * 3 - 1
              ) {
                doubleList3.push(location);
              } else if (
                index >= palletsInDoubleRow * 3 &&
                index <= palletsInDoubleRow * 4 - 1
              ) {
                doubleList4.push(location);
              }
            }
          } else {
            if (totalPbcRows === 1) {
              if (index > pbcRowsStartPalletIndex - 1) {
                pbcList1.push(location);
              }
            } else if (totalPbcRows === 2) {
              if (
                index > pbcRowsStartPalletIndex &&
                index <= pbcRowsStartPalletIndex + transitionPallet / 2
              ) {
                pbcList1.push(location);
              } else if (
                index >
                pbcRowsStartPalletIndex + transitionPallet / 2
              ) {
                pbcList2.push(location);
              }
            } else if (totalPbcRows === 3) {
              if (
                index > pbcRowsStartPalletIndex &&
                index <= pbcRowsStartPalletIndex + transitionPallet / 2
              ) {
                pbcList1.push(location);
              } else if (
                index > pbcRowsStartPalletIndex + transitionPallet / 2 &&
                index <= pbcRowsStartPalletIndex + transitionPallet
              ) {
                pbcList2.push(location);
              } else if (
                index > pbcRowsStartPalletIndex + transitionPallet &&
                index <=
                  pbcRowsStartPalletIndex +
                    transitionPallet +
                    transitionPallet / 2
              ) {
                pbcList3.push(location);
              }
            } else if (totalPbcRows === 4) {
              if (
                index > pbcRowsStartPalletIndex &&
                index <= pbcRowsStartPalletIndex + transitionPallet / 2
              ) {
                pbcList1.push(location);
              } else if (
                index > pbcRowsStartPalletIndex + transitionPallet / 2 &&
                index <= pbcRowsStartPalletIndex + transitionPallet
              ) {
                pbcList2.push(location);
              } else if (
                index > pbcRowsStartPalletIndex + transitionPallet &&
                index <=
                  pbcRowsStartPalletIndex +
                    transitionPallet +
                    transitionPallet / 2
              ) {
                pbcList3.push(location);
              } else if (
                index >
                  pbcRowsStartPalletIndex +
                    transitionPallet +
                    transitionPallet / 2 &&
                index <= pbcRowsStartPalletIndex + transitionPallet * 2
              ) {
                pbcList4.push(location);
              }
            }
          }
        }
      });

      //Combining All Pallet Lists
      if (transitionPallet === null) {
        sectionList.push(blockSingleColumnList);
      } else {
        if (doubleList1.length !== 0) {
          sectionList.push(doubleList1);
        }
        if (doubleList2.length !== 0) {
          sectionList.push(doubleList2);
        }
        if (doubleList3.length !== 0) {
          sectionList.push(doubleList3);
        }
        if (doubleList4.length !== 0) {
          sectionList.push(doubleList4);
        }

        if (pbcList1.length !== 0) {
          sectionList.push(pbcList1);
        }
        if (pbcList2.length !== 0) {
          sectionList.push(pbcList2);
        }
        if (pbcList3.length !== 0) {
          sectionList.push(pbcList3);
        }
        if (pbcList4.length !== 0) {
          sectionList.push(pbcList4);
        }
      }
      //  if locationOrientation is S the first position should start from south and end to north
      const reversedList = [];
      if (locationMetaData.locationOrientation == "S") {
        sectionList.forEach((item) => {
          const temp = item.reverse();
          reversedList.push(temp);
        });

        sectionList = reversedList;
      }
    }
    return sectionList;
  };

  const getMetaDataOfLocation = (location, type) => {
    if (type === "block") {
      return {
        latitude: location.latitude,
        longitude: location.longitude,
        location: location.location,
        totalPositions: location.totalPositions,
        positionTransition: location.positionTransition,
        positionOrientation: location.positionOrientation,
        locationOrientation: location.locationOrientation,
        positionLength: location.positionLength,
      };
    } else {
      return {
        latitude: location.latitude,
        longitude: location.longitude,
        location: location.location,
        totalPositions: location.totalPositions,
        locationOrientation: location.locationOrientation,
        positionLength: location.positionLength,
      };
    }
  };
  const GenerateHTMLBlockIcon = ({
    locationMetaData,
    locationsList,
    type,
    blocksCount,
    racksCount,
    marker,
  }) => {
    if (locationMetaData.totalPositions !== 0) {
      let transitionPallet = locationMetaData.positionTransition;

      let sectionsData = markerSectionGenerator(
        locationsList,
        transitionPallet,
        locationMetaData.doubleRows,
        locationMetaData.pbcRows,
        locationMetaData.totalPositions,
        locationMetaData
      );

      //Find the Location Orientation of the selected location
      let locationOrientationOfThisLocation = getLocationOrientationOfStorage(
        type,
        sectionsData
      );

      //Reverse or keep the list straight according to the location Orientation of the selected location
      let renderList;

      if (type === "rack") {
        //Get Rendering Data according to position orientation
        renderList = getBlockRackDataWithLocationOrientation(
          locationOrientationOfThisLocation,
          sectionsData,
          "rack"
        );
        //Render Double and Single Pallet Rack
        return renderRackPallet(renderList, racksCount);
      } else if (type === "block") {
        //Get Rendering Data according to position orientation
        renderList = getBlockRackDataWithLocationOrientation(
          locationOrientationOfThisLocation,
          sectionsData,
          "block"
        );
        //Check is the block Vertical
        // --RollBack if needed
        let isLocationVertical = findIsLocationVertical(renderList);
        if (locationMetaData.positionOrientation == "N") {
          const temp = sectionsData.pop();
          sectionsData.unshift(temp);
        }
        // --RollBack if needed
        if (sectionsData.length === 1) {
          //Render Single Pallet Block
          return renderPreFilledSingleBlockPallet(
            renderList,
            isLocationVertical,
            blocksCount
          );
        } else {
          //Render Double Pallet Block
          return renderPreFilledDoubleBlockPallet(
            sectionsData,
            isLocationVertical,
            locationMetaData,
            blocksCount,
            marker
          );
        }
      }
    }
  };
  // Memoize the rack storage rendering logic for performance optimization
  const renderRackMarkers = useMemo(() => {
    if (
      plantConfig &&
      isBlockRackNonLgvEnabled() &&
      mapObject &&
      rackData &&
      rackData.length !== 0
    ) {
      let rackStorageData = [...rackData];
      let racksCount = countRacksUsingLPNs(rackStorageData);
      let formattedRackData = formatBlockDataStructure(rackStorageData, "rack");

      return Object.values(formattedRackData).map((rack, index) => {
        let metaData = Object.values(rack)[0];
        let { latitude, longitude, location } = getMetaDataOfLocation(
          metaData,
          "rack"
        );

        // Convert Start Coordinates
        let convertedStart;
        let convertedPoints;
        let convertedLatLng;
        let convertedPositionObj;

        const { rackStorage } = plantConfig.indoorMap;
        const {
          bounds,
          eachItemOffset,
          groupOffset,
          marker: markerConfig,
        } = rackStorage;

        // Send the Rack bounds to Coordinate Converter
        convertedStart = mapCoordinate(longitude, latitude, "rack", bounds);
        convertedPoints = L.point(convertedStart.x, convertedStart.y);
        convertedLatLng =
          mapObject.map.target.layerPointToLatLng(convertedPoints);

        // Convert X and Y to Lat and Lng with offsets
        if (eachItemOffset[location]) {
          let offset = eachItemOffset[location];
          convertedPositionObj = getLocationGroupOffset(
            convertedLatLng,
            offset
          );
        } else {
          convertedPositionObj = getLocationGroupOffset(
            convertedLatLng,
            groupOffset
          );
        }

        // Destructure Overall Blocks Height and Width and Single stack height and width
        const blockSizeConfig = {
          rackWidth: markerConfig.size.width,
          rackHeight: markerConfig.size.height,
        };
        // Set Dynamic Width and Height of Rack from Config
        setRackStorageSize(blockSizeConfig);

        // Generate Rack Icon
        let reactIcon = convertReactComponentToHtmlIcon(
          <GenerateHTMLBlockIcon
            locationMetaData={metaData}
            locationsList={rack}
            type="rack"
            racksCount={racksCount}
          />,
          "pallets-wrapper"
        );

        // Render Rack Marker
        if (convertedPositionObj !== undefined) {
          return (
            <Marker
              key={index}
              position={convertedPositionObj}
              icon={reactIcon}
            >
              <Popup>{location}</Popup>
            </Marker>
          );
        }
      });
    }
    return null; // Return null if conditions are not met
  }, [mapObject, rackData, plantConfig]); // Dependencies to re-run the effect

  return (
    <>{renderRackMarkers}</> // Render the memoized rack markers
  );
};

export default RenderRackStorage;
