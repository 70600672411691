import "../../assets/css/Map/map.css";
import "../../assets/css/YardView/YardView.css";
import L from "leaflet";
import { ImageOverlay, MapContainer } from "react-leaflet";
import { useEffect, React, useState, useContext, useMemo, useRef } from "react";
import {
  getLocalStorageItem,
  mapCoordinate,
  onDragging,
  onDraggingEnd,
  getPlantMapImageUrl,
} from "../../util/helper/helperFunctions";

import { AppContext } from "../../Context/Context";

import zoomInIcon from "../../assets/icons/add.png";
import zoomOutIcon from "../../assets/icons/minus.png";
import zoomRefreshIcon from "../../assets/icons/refresh.png";
import RenderLgv from "./RenderLgv";
import RenderDockDoor from "./RenderDockdoor";
import RenderProductionLines from "./RenderProductionLines";
import RenderRackStorage from "./RenderRackStorage";
import RenderBlockStorage from "./RenderBlockStorage";

const zoomTransFormArr = [
  "scale(1)",
  "scale(1.2)",
  "scale(1.4)",
  "scale(1.6)",
  "scale(1.8)",
  "scale(2)",
  "scale(2.2)",
  "scale(2.4)",
  "scale(2.6)",
  "scale(2.8)",
  "scale(3)",
  "scale(3.2)",
  "scale(3.4)",
  "scale(3.6)",
  "scale(3.8)",
  "scale(4)",
  "scale(4.2)",
  "scale(4.4)",
  "scale(4.6)",
  "scale(4.8)",
  "scale(5)",
];

const TimeMachineMap = ({ liveData, isDataLoading }) => {
  /**Context API */
  const { plantConfig } = useContext(AppContext);
  const { appConfig, setTimeRangePopup } = useContext(AppContext);
  const { timeMachineTimeFrames } = useContext(AppContext);
  const { connectionStatus, setConnectionStatus } = useContext(AppContext);

  /**Hooks */
  const mapRef = useRef(null);
  const [mapObject, setMapObject] = useState();
  const [zoomLevel, setZoomLevel] = useState(0);

  //Scroll Zoom Variables
  let currentZoom = 1;
  let minZoom = 1;
  let maxZoom = 5;
  let stepSize = 1;

  /**Component Mount */
  useEffect(() => {
    if (!mapObject) return;

    const mapContainer = document.getElementById(
      "time-machine-map-id-reference"
    );
    let transFormString = zoomTransFormArr[zoomLevel];
    if (transFormString) {
      mapContainer.style.transform = transFormString;
    }
  }, [zoomLevel]);

  useEffect(() => {
    mapObject && mapObject.map.target.doubleClickZoom.disable();
    if (mapObject) {
      centerMapView();
      const mapContainer = document.getElementById(
        "time-machine-map-id-reference"
      );
      mapContainer.addEventListener("wheel", (event) => {
        // Zoom in or out based on the scroll direction
        let direction = event.deltaY > 0 ? -1 : 1;
        scrollZoom(direction);
      });
    }
  }, [mapObject]);

  /**Map Handlers */
  const centerMapView = (unit1, unit2) => {
    let convertedCoordinates;
    if (unit1 && unit2) {
      convertedCoordinates = mapCoordinate(unit1, unit2);
    } else {
      convertedCoordinates = mapCoordinate(275000, 70000);
    }
    let convertedPoints = L.point(
      convertedCoordinates.x,
      convertedCoordinates.y
    );
    let convertedLatLng =
      mapObject.map.target.layerPointToLatLng(convertedPoints);
    mapObject.map.target.setView([convertedLatLng.lat, convertedLatLng.lng]);
    // setConvertedCenter(convertedLatLng);
  };

  const mapWhenReadyHandler = (map) => {
    setMapObject({ map });
  };
  const resetZoom = () => {
    document.getElementById("time-machine-map-id-reference").style.transform =
      "scale(1)";
    setZoomLevel(0);
  };

  //Attach scroll Zoom event to Map Container
  const scrollZoom = (direction) => {
    let newZoom = currentZoom + direction * stepSize;

    // Limit the zoom level to the minimum and maximum
    // values
    if (newZoom < minZoom || newZoom > maxZoom) {
      return;
    }

    currentZoom = newZoom;

    // Update the CSS transform of the image to scale it
    let mapContainer = document.querySelector("#time-machine-map-id-reference");
    mapContainer.style.transform = "scale(" + currentZoom + ")";
  };

  /**Feature Components */
  const RenderMap = useMemo(() => {
    const locationCode = getLocalStorageItem("locationCode");
    if (plantConfig && appConfig && mapObject) {
      let img = getPlantMapImageUrl(appConfig, locationCode, "WAREHOUSE_MAP");
      //Get Plant Config
      const { mapBg } = plantConfig.indoorMap;
      if (img && mapBg) {
        //Get Image, Image Bounds (limit), X1,Y1 , X2,Y2 coordinates of image and Image bg color
        const { bounds, coordinates, bgColor } = mapBg;
        //(X1,Y1) and (X2,Y2) data of map coordinates
        const { startX, startY, endX, endY } = coordinates;

        //Downscale start coordinate
        let startConvertedCoordinates = mapCoordinate(
          startX,
          startY,
          "map",
          bounds
        );
        let startConvertedPoints = L.point(
          startConvertedCoordinates.x,
          startConvertedCoordinates.y
        );
        let startConvertedLatLng =
          mapObject.map.target.layerPointToLatLng(startConvertedPoints);

        //Downscale end coordinate
        let endConvertedCoordinates = mapCoordinate(endX, endY, "map", bounds);
        let endConvertedPoints = L.point(
          endConvertedCoordinates.x,
          endConvertedCoordinates.y
        );
        let endConvertedLatLng =
          mapObject.map.target.layerPointToLatLng(endConvertedPoints);

        //Final Downscaled bounds for Image
        let overlayBounds = [
          [startConvertedLatLng.lat, startConvertedLatLng.lng],
          [endConvertedLatLng.lat, endConvertedLatLng.lng],
        ];

        // setMapLoader(false);
        return <ImageOverlay url={img} bounds={overlayBounds} />;
      }
    }
  }, [mapObject, plantConfig, appConfig]);

  const ZoomControlCenter = () => {
    return (
      <div className="zoom-control-center">
        <button
          id="map-zoom-in-btn"
          className="zoom-controls zoom-in-control"
          disabled={zoomLevel >= 20}
          onClick={() => setZoomLevel((curr) => ++curr)}
        >
          <img alt="zoom-in-icon" src={zoomInIcon} />
        </button>
        <button
          id="map-zoom-out-btn"
          className="zoom-controls zoom-out-control"
          disabled={zoomLevel < 1}
          onClick={() => setZoomLevel((curr) => --curr)}
        >
          <img
            alt="zoom-out-icon"
            style={{ opacity: zoomLevel < 1 ? "0.5" : "1" }}
            src={zoomOutIcon}
          />
        </button>
        <button
          className="zoom-controls zoom-reset-controls"
          disabled={zoomLevel == 0}
          onClick={() => resetZoom()}
        >
          <img
            alt="zoom-refresh-icon"
            style={{ opacity: zoomLevel == 0 ? "0.5" : "1" }}
            src={zoomRefreshIcon}
          />
        </button>
      </div>
    );
  };

  const isTimeFrameAvailable = () => {
    const { fromDate, fromTime, toDate, toTime } = timeMachineTimeFrames;
    if (fromDate && fromTime && toDate && toTime) {
      return true;
    }
    return false;
  };

  const renderLoader = useMemo(() => {
    if (!connectionStatus) {
      return (
        <div className="loader">
          <>
            <h4>No Internet. Please check your Internet Connection...</h4>
            <div className="spinner"></div>
          </>
        </div>
      );
    } else if (!isTimeFrameAvailable() || isDataLoading) {
      return (
        <div className="loader">
          {isDataLoading ? (
            <>
              <h4>Loading Time Machine..</h4>
              <div className="spinner"></div>
            </>
          ) : !isTimeFrameAvailable() ? (
            <h4>Please select Time Frame to Initiate Time Machine..</h4>
          ) : null}
        </div>
      );
    } else {
      return null;
    }
  }, [isDataLoading, connectionStatus]);

  return (
    <div
      className={`map-container-wrapper time-machine-map-container new-ui plant-${getLocalStorageItem(
        "locationCode"
      )}`}
    >
      <div className="time-options-section">
        <div
          onClick={() => setTimeRangePopup(true)}
          className="time-frame-displayer"
        >
          {isTimeFrameAvailable() ? (
            <>
              <div className="from-date">
                {timeMachineTimeFrames.fromDate} -{" "}
                {timeMachineTimeFrames.fromTime}
              </div>
              to
              <div className="to-date">
                {timeMachineTimeFrames.toDate} - {timeMachineTimeFrames.toTime}
              </div>
            </>
          ) : (
            <p>Click here to select Time Frame</p>
          )}
        </div>
      </div>

      {renderLoader}

      <div
        onMouseDown={(event) => onDragging(event)}
        onMouseUp={(event) => onDraggingEnd(event)}
        id="time-machine-map-container-id"
        className="time-machine-container"
      >
        <MapContainer
          id="time-machine-map-id-reference"
          style={{
            height: "100%",
            width: "100%",
            borderRadius: "10px",
            background: "green",
          }}
          center={[0, 0]}
          zoom={0}
          maxZoom={6}
          ref={mapRef}
          maxBoundsViscosity={1.0}
          crs={L.CRS.Simple}
          attributionControl={false}
          scrollWheelZoom={false}
          whenReady={(map) => mapWhenReadyHandler(map)}
          // dragging={isMapDraggable}
          zoomControl={false}
          disableDoubleClickZoom={false}
        >
          <RenderLgv
            lgvData={liveData.LGV}
            plantConfig={plantConfig}
            mapObject={mapObject}
          />

          <RenderProductionLines
            linesData={liveData.PRODUCTION_LINE}
            plantConfig={plantConfig}
            mapObject={mapObject}
          />

          <RenderDockDoor
            dockDoorData={liveData.DOCKDOOR}
            plantConfig={plantConfig}
            mapObject={mapObject}
          />

          <RenderRackStorage
            rackData={liveData.RACK}
            plantConfig={plantConfig}
            mapObject={mapObject}
          />

          <RenderBlockStorage
            blockData={liveData.BLOCK}
            plantConfig={plantConfig}
            mapObject={mapObject}
          />

          {isTimeFrameAvailable() && RenderMap}
        </MapContainer>
      </div>

      <ZoomControlCenter />
    </div>
  );
};

export default TimeMachineMap;
