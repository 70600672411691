import React, { useMemo, useState,useEffect } from "react";
import { Marker } from "react-leaflet";
import L from "leaflet";
import DockIcon from "../DockDoor/DockIcon"; // Assuming DockIcon is a separate component
import {
  convertReactComponentToHtmlIcon,
  mapCoordinate,
} from "../../util/helper/helperFunctions";

const RenderDockDoor = ({
  plantConfig,
  mapObject,
  dockDoorData,
}) => {
//   console.log("doockrro", dockDoorData);
  const [convertedDoorDockData, setConvertedDockDoorData] = useState([]);

  useEffect(() => {
    // console.log("Render LGV", lgvData);
    if (dockDoorData) {
        dockDoorCoordinateConverter(dockDoorData);
    }
  }, [dockDoorData]);

  const dockDoorCoordinateConverter = () => {
    if (plantConfig && mapObject && dockDoorData.length) {
      let { dockDoors } = plantConfig.indoorMap;
      dockDoorData.forEach((dockData) => {
        let convertedCoordinates = mapCoordinate(
          dockData["longitude"],
          dockData["latitude"],
          "dockdoors",
          dockDoors.bounds
        );

        let coordinateCorrection = dockDoors.groupOffset;
        let convertedPoints = L.point(
          convertedCoordinates.x + coordinateCorrection.x,
          convertedCoordinates.y + coordinateCorrection.y
        );
        let convertedLatLng =
          mapObject.map.target.layerPointToLatLng(convertedPoints);


          dockData['currentPos'] = {
            lat: convertedLatLng.lat,
            lng: convertedLatLng.lng,
          };



        // dockData.forEach((pallet) => {
        //   pallet.currentPos = {
        //     lat: convertedLatLng.lat,
        //     lng: convertedLatLng.lng,
        //   };
        // });
      });
      // setMapLoader(false);
      setConvertedDockDoorData(dockDoorData);
    }
  };

  const renderDockDoorMarkers = useMemo(() => {
    if (plantConfig && convertedDoorDockData ) {
      let { dockDoors } = plantConfig.indoorMap;
      return convertedDoorDockData.map((dockMarker, index) => {
        let height = dockDoors.marker.size[1];
        let width = dockDoors.marker.size[0];
        let markerData = dockMarker;
        let isHorizontal = index > 31 && markerData[0]?.ANGLE === 0;

        if (isHorizontal) {
          let temp = height;
          height = width;
          width = temp;
        }

        let reactIcon = convertReactComponentToHtmlIcon(
          <DockIcon
            plantConfig={plantConfig}
            dockMode={1}
            dockData={[markerData]}
            index={index}
          />,
          `dock-door ${
            markerData.READY_FOR_LOADING === 1
              ? "ready-to-load"
              : "not-ready-to-load"
          } ${isHorizontal ? "dock-door-with-90-angle" : ""}`,
          [width, height],
          [0, 55]
        );

        let coordinateCorrection = {
          x: dockDoors?.eachItemOffset?.[markerData.DOCK]?.x || 0,
          y: dockDoors?.eachItemOffset?.[markerData.DOCK]?.y || 0,
        };

        return (
          <Marker
            key={index}
            type="dock"
            data={markerData}
            // eventHandlers={{
            //   click: () => {
            //     // setSelectedMarkerData(markerData);
            //     // setSelectedMarkerType("dock");
            //     // setIsMarkerPopupOpen(true);
            //   },
            // }}
            riseOnHover={true}
            position={[
              markerData.currentPos.lat + coordinateCorrection.y,
              markerData.currentPos.lng + coordinateCorrection.x,
            ]}
            icon={reactIcon}
          />
        );
      });
    }
    return null; // In case conditions are not met, render nothing
  }, [
    convertedDoorDockData,
    plantConfig
  ]);

  return <>{renderDockDoorMarkers}</>;
};

export default RenderDockDoor;
